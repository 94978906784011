import {useEffect, useRef} from 'preact/hooks';

/**
 * Prevent the body from scrolling depending on a boolean state.
 * This is particularly useful for drawers, modals, and other components that
 * should prevent the body from scrolling when they are open.
 * @example
 * ```ts
 * const [isDrawerOpen, setIsDrawerOpen] = useState(false);
 * useScrollCapture(isDrawerOpen);
 * ```
 */
export default function useScrollCapture(captureScroll: boolean) {
  // Prevent the body from scrolling when captureScroll is true
  const scrollPosition = useRef(window.scrollY);
  useEffect(() => {
    const bodyStyle = document.body.style;
    if (captureScroll) {
      scrollPosition.current = window.scrollY;
      bodyStyle.overflow = 'hidden';
      bodyStyle.position = 'fixed';
      bodyStyle.top = `-${scrollPosition.current}px`;
      bodyStyle.width = '100%';
    } else {
      bodyStyle.removeProperty('overflow');
      bodyStyle.removeProperty('position');
      bodyStyle.removeProperty('top');
      bodyStyle.removeProperty('width');
      window.scrollTo(0, scrollPosition.current);
    }
  }, [captureScroll]);
}

export class ScrollCapture {
  scrollPosition: number;
  bodyStyle: CSSStyleDeclaration;

  constructor() {
    this.scrollPosition = window.scrollY;
    this.bodyStyle = document.body.style;
  }

  captureScroll() {
    this.scrollPosition = window.scrollY;
    this.bodyStyle.overflow = 'hidden';
    this.bodyStyle.position = 'fixed';
    this.bodyStyle.top = `-${this.scrollPosition}px`;
    this.bodyStyle.width = '100%';
  }

  releaseScroll() {
    this.bodyStyle.removeProperty('overflow');
    this.bodyStyle.removeProperty('position');
    this.bodyStyle.removeProperty('top');
    this.bodyStyle.removeProperty('width');
    window.scrollTo(0, this.scrollPosition);
  }
}
